<template>
  <div v-if="!isLoading">
    <div class="login-content mx-auto bg-white text-center">
      <div class="login-header bg-orange text-white px-3">
        <LoginSvg />
        <span class="Raleway-SemiBold ml-2">DRIVE FİKİR PLATFORMU</span>
      </div>
      <div class="login-body turkuaz-login mx-auto px-4">
        <p>Hoş Geldiniz!</p>
        <form id="frmUserName">
          <div class="form-group pt-2 mb-0 text-left" id="login-input-area">
            <svg
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 31 31"
              style="enable-background: new 0 0 31 31"
              xml:space="preserve"
            >
              <g>
                <path
                  class="st0"
                  d="M20.41,18.71c2.05-1.61,3.36-4.11,3.36-6.91c0-4.86-3.94-8.8-8.8-8.8c-4.86,0-8.8,3.94-8.8,8.8c0,2.8,1.32,5.3,3.36,6.91C5.87,20.58,3.28,24.27,3,28.6h1.6c0.31-4.05,2.94-7.45,6.57-8.88c1.15,0.55,2.44,0.88,3.8,0.88c1.36,0,2.65-0.32,3.8-0.88c3.63,1.43,6.26,4.82,6.57,8.88h1.6C26.66,24.27,24.07,20.58,20.41,18.71L20.41,18.71z M14.97,19c-3.98,0-7.2-3.22-7.2-7.2c0-3.98,3.23-7.2,7.2-7.2c3.98,0,7.2,3.22,7.2,7.2C22.17,15.78,18.95,19,14.97,19L14.97,19z M14.97,19"
                ></path>
              </g>
            </svg>
            <input
              v-model="turkuazLogin.userName"
              type="text"
              class="form-control"
              placeholder="Kullanıcı Adı"
            />
          </div>
          <div
            id="userNameError"
            class="form-group invalid-feedback"
            :style="[
              requiredField.includes('userName') ? { display: 'block' } : '',
            ]"
          >
            Seçim yapılması zorunludur!
          </div>
          <div class="form-group pt-2 mb-0 text-left">
            <input
              v-model="turkuazLogin.password"
              class="form-control"
              placeholder="Şifre"
              type="password"
            />
          </div>
          <div
            id="passwordError"
            class="form-group invalid-feedback"
            :style="[
              requiredField.includes('password') && { display: 'block' },
            ]"
          >
            Seçim yapılması zorunludur!
          </div>
          <div
            class="d-flex align-items-center justify-content-center pb-4"
            id="login-area"
            style="text-align: center"
          >
            <button
              @click="onLoginBack"
              type="button"
              id="btnBack"
              class="
                btn-innovation-radius-left btn-innovation-sms-radius
                bg-orange
                icon-left
                Roboto-Bold
                mt-5
                w-100
              "
            >
              GERİ
            </button>
            <button
              type="button"
              class="
                ml-2
                btn-innovation-radius btn-innovation-sms-radius
                icon-right
                Roboto-Bold
                mt-5
                w-100
              "
              style="background: #71c119 !important"
              @click="onLogin"
            >
              GİRİŞ
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div
    v-else
    class="d-flex align-items-center justify-content-center w-100"
    style="height: 100vh"
  >
    <PulseLoader :color="color" :size="size"></PulseLoader>
  </div>
</template>

<script>
import LoginSvg from "../Main/LoginSvg.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
export default {
  name: "LoginTurkuaz",
  data() {
    return {
      turkuazLogin: {
        userName: "",
        password: "",
      },
      color: "#FFC87F",
      size: "36px",
      isLoading: false,
      requiredField: [],
    };
  },
  components: {
    LoginSvg,
    PulseLoader,
  },
  methods: {
    onLogin() {
      this.requiredField = [];
      if (
        this.turkuazLogin.userName != "" &&
        this.turkuazLogin.password != ""
      ) {
        this.isLoading = true,
        this.$emit("on-login", this.turkuazLogin);
      } else {
        if (this.turkuazLogin.userName == "")
          this.requiredField.push("userName");
        if (this.turkuazLogin.password == "")
          this.requiredField.push("password");
      }
    },
    onLoginBack() {
      this.$emit("on-login-back");
    },
  },
};
</script>

<style></style>
