<template>
  <div class="login__container">
    <div
      class="login-content mx-auto bg-white text-center"
      v-if="!isTurkuazLogin"
    >
      <div class="login-header bg-orange text-white px-3">
        <LoginSvg />
        <span class="Raleway-SemiBold ml-2">DRIVE FİKİR PLATFORMU</span>
      </div>
      <div class="login-body mx-auto px-4">
        <p>Hoş Geldiniz!</p>
        <form id="frmUserName" v-on:submit.prevent="sendOTP">
          <div
            v-if="isSmsLogin && !isSuccessOTP"
            class="form-group pt-2 mb-0 text-left"
            id="login-input-area"
          >
            <svg
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 31 31"
              style="enable-background: new 0 0 31 31"
              xml:space="preserve"
            >
              <g>
                <path
                  class="st0"
                  d="M20.41,18.71c2.05-1.61,3.36-4.11,3.36-6.91c0-4.86-3.94-8.8-8.8-8.8c-4.86,0-8.8,3.94-8.8,8.8c0,2.8,1.32,5.3,3.36,6.91C5.87,20.58,3.28,24.27,3,28.6h1.6c0.31-4.05,2.94-7.45,6.57-8.88c1.15,0.55,2.44,0.88,3.8,0.88c1.36,0,2.65-0.32,3.8-0.88c3.63,1.43,6.26,4.82,6.57,8.88h1.6C26.66,24.27,24.07,20.58,20.41,18.71L20.41,18.71z M14.97,19c-3.98,0-7.2-3.22-7.2-7.2c0-3.98,3.23-7.2,7.2-7.2c3.98,0,7.2,3.22,7.2,7.2C22.17,15.78,18.95,19,14.97,19L14.97,19z M14.97,19"
                ></path>
              </g>
            </svg>
            <input       
              v-mask="'(###) ### ## ##'"
              v-model="phone"
              type="text"
              id="txtPhoneNumber"
              class="form-control"
              placeholder="Telefon Numarası"
              maxlength="15"
            />
            <input type="hidden" id="hiddenUserId" class="form-control" />
            <div
              id="wrongUserName"
              class="invalid-feedback"
              style="display: none"
            >
              Kullanıcı adı geçersiz!
            </div>
            <div
              id="wrongTelephoneNumber"
              class="invalid-feedback"
              style="display: none"
            ></div>
          </div>

          <div class="form-group container">
            <div class="row" style="text-align: center" v-if="!isSmsLogin">
              <button
                @click="isTurkuazLogin = true"
                type="button"
                id="btn-login-azure"
                class="btn-innovation-radius bg-orange Roboto-Bold mt-1 w-100 mx-auto"
              >
                <div>TURKUAZ HESABI İLE GİRİŞ YAP</div>
              </button>
              <button
                @click="isSmsLogin = true"
                type="button"
                id="btn-login-sms"
                class="btn-innovation-radius bg-orange Roboto-Bold mt-5 w-100 mx-auto"
              >
                SMS İLE GİRİŞ YAP
              </button>
            </div>

            <div
              v-if="isSmsLogin && !isSuccessOTP"
              class="d-flex align-items-center justify-content-center"
              id="login-area"
              style="text-align: center"
            >
              <button
                @click="isSmsLogin = false"
                type="button"
                id="btnBack"
                class="btn-innovation-radius-left btn-innovation-sms-radius bg-orange icon-left Roboto-Bold mt-5 w-100"
              >
                GERİ
              </button>
              <button
                :disabled="phone.length == 0"
                @click="sendOTP"
                type="button"
                id="btnUserName"
                class="ml-2 btn-innovation-radius btn-innovation-sms-radius icon-right Roboto-Bold mt-5 w-100"
                style="background: #71c119 !important"
              >
                GİRİŞ
              </button>
            </div>
          </div>
        </form>

        <form v-if="isSuccessOTP" id="frmPassword" v-on:submit.prevent="loginWithOTP">
          <div class="cutdowntimer">{{ timeSeconds }}</div>
          <div class="form-group pt-2 mb-0 text-left">
            <svg
              version="1.1"
              id="Layer_2"
              x="0px"
              y="0px"
              viewBox="0 0 50 60"
              style="enable-background: new 0 0 50 60"
              xml:space="preserve"
            >
              <g>
                <path
                  d="M47.74,25.67h-7.46v-9.75c0-4.54-1.43-8.29-4.31-11.25c-2.89-2.96-6.52-4.43-10.95-4.43c-4.46,0-8.14,1.46-11.02,4.43
		c-2.89,2.96-4.35,6.71-4.35,11.25v9.75H2.26c-1.16,0-2.06,0.9-2.06,2.06v29.96c0,1.16,0.9,2.06,2.06,2.06h45.49
		c1.09,0,2.06-0.9,2.06-2.06V27.73C49.8,26.57,48.83,25.67,47.74,25.67L47.74,25.67z M13.81,15.92c0-3.38,1.05-6.15,3.11-8.29
		c2.1-2.18,4.8-3.26,8.1-3.26c3.26,0,5.91,1.1,7.99,3.26c2.05,2.15,3.11,4.91,3.11,8.29v9.75H13.81V15.92z M45.64,55.59H4.36v-25.8
		h41.29V55.59z M45.64,55.59"
                ></path>
                <path
                  d="M22.92,42.77v8.63c0,1.16,0.93,2.14,2.1,2.14c1.16,0,2.06-0.98,2.06-2.14v-8.63c1.76-0.79,3-2.4,3-4.2
		c0-1.24-0.54-2.3-1.58-3.22c-1.03-0.92-2.17-1.39-3.49-1.39c-1.35,0-2.54,0.46-3.6,1.39c-1.04,0.91-1.58,1.98-1.58,3.22
		C19.84,40.33,21.16,41.98,22.92,42.77L22.92,42.77z M22.92,42.77"
                ></path>
              </g>
            </svg>
            <input
              v-model="passwordOTP"
              type="password"
              id="txtPassword"
              class="form-control"
              placeholder="Şifre"
            />
          </div>

          <div class="form-group">
            <button
              @click="loginWithOTP"
              type="button"
              id="btn-login"
              class="btn-innovation-radius bg-orange icon-right Roboto-Bold mt-5 w-100"
              :disabled="passwordOTP.length == 0"
            >
              GİRİŞ
            </button>
          </div>
        </form>
      </div>
      <div class="login-footer mx-auto text-center pt-3 pb-4">
        <a href="#" style="display: none"><u>Şifremi Unuttum</u></a>
      </div>
    </div>
    <div v-else>
      <LoginTurkuaz
        v-if="!isSuccessOTP"
        @on-login="onLoginTurkuaz($event)"
        @on-login-back="onLoginBack"
      />
      <div v-else class="login-content mx-auto bg-white text-center">
        <div class="login-body mx-auto px-4">
          <div class="form-group container">
            <form id="frmPassword">
              <div class="cutdowntimer">{{ timeSeconds }}</div>
              <div>
                Lütfen sistemde kayıtlı telefon numaranıza gelen kodu giriniz.
              </div>
              <div class="form-group pt-2 mb-0 text-left">
                <svg
                  version="1.1"
                  id="Layer_2"
                  x="0px"
                  y="0px"
                  viewBox="0 0 50 60"
                  style="enable-background: new 0 0 50 60"
                  xml:space="preserve"
                >
                  <g>
                    <path
                      d="M47.74,25.67h-7.46v-9.75c0-4.54-1.43-8.29-4.31-11.25c-2.89-2.96-6.52-4.43-10.95-4.43c-4.46,0-8.14,1.46-11.02,4.43
        c-2.89,2.96-4.35,6.71-4.35,11.25v9.75H2.26c-1.16,0-2.06,0.9-2.06,2.06v29.96c0,1.16,0.9,2.06,2.06,2.06h45.49
        c1.09,0,2.06-0.9,2.06-2.06V27.73C49.8,26.57,48.83,25.67,47.74,25.67L47.74,25.67z M13.81,15.92c0-3.38,1.05-6.15,3.11-8.29
        c2.1-2.18,4.8-3.26,8.1-3.26c3.26,0,5.91,1.1,7.99,3.26c2.05,2.15,3.11,4.91,3.11,8.29v9.75H13.81V15.92z M45.64,55.59H4.36v-25.8
        h41.29V55.59z M45.64,55.59"
                    ></path>
                    <path
                      d="M22.92,42.77v8.63c0,1.16,0.93,2.14,2.1,2.14c1.16,0,2.06-0.98,2.06-2.14v-8.63c1.76-0.79,3-2.4,3-4.2
        c0-1.24-0.54-2.3-1.58-3.22c-1.03-0.92-2.17-1.39-3.49-1.39c-1.35,0-2.54,0.46-3.6,1.39c-1.04,0.91-1.58,1.98-1.58,3.22
        C19.84,40.33,21.16,41.98,22.92,42.77L22.92,42.77z M22.92,42.77"
                    ></path>
                  </g>
                </svg>
                <input
                  v-model="passwordOTP"
                  type="password"
                  id="txtPassword"
                  class="form-control"
                  placeholder="Şifre"
                />
              </div>

              <div class="form-group">
                <button
                  @click="turkuazLoginWithOTP"
                  type="button"
                  id="btn-login"
                  class="btn-innovation-radius bg-orange icon-right Roboto-Bold mt-5 w-100"
                  :disabled="passwordOTP.length == 0"
                >
                  GİRİŞ
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoginTurkuaz from "../components/Login/LoginTurkuaz.vue";
import LoginSvg from "../components/Main/LoginSvg.vue";
import { refreshToken } from "../services/helpers";
import CryptoJS from "crypto-js";
export default {
  name: "Login",
  data() {
    return {
      account: null,
      accounts: [],
      color: "#FFC87F",
      size: "36px",
      tmpUser: null,
      isSmsLogin: false,
      isTurkuazLogin: false,
      phone: "",
      isSuccessOTP: false,
      passwordOTP: "",
      timeSeconds: null,
      countDown: 0,
      isSignInAzure: false,
      turkuazLoginData: {},
    };
  },
  components: {
    LoginSvg,
    LoginTurkuaz,
  },
  computed: {
    ...mapGetters({
      getSendOTP: "notiflow/getSendOTP",
    }),
  },
  async created() {
    //this.SignIn();
    // this.isSignInAzure = localStorage.getItem("isSignInAzure");
    // if (
    //   this.isSignInAzure != null &&
    //   this.isSignInAzure != false &&
    //   this.isSignInAzure != "undefined"
    // ) {
    //   this.SignIn();
    // }
  },
  methods: {
    async SignIn() {
      localStorage.setItem("isSignInAzure", true);
      this.$msalInstance
        .handleRedirectPromise()
        .then(async () => {
          this.accounts = this.$msalInstance.getAllAccounts();
          if (this.accounts.length === 0) {
            // No user signed in
            await this.$msalInstance
              .loginRedirect({})
              .then(() => {
                this.loginAccount(this.accounts);
              })
              .catch(() => {});
          } else {
            this.loginAccount(this.accounts);
          }
        })
        .catch(() => {
          this.$router.push("/auth");
        });
    },
    async loginAccount(accounts) {
      this.account = accounts[0];
      this.cryptoUserName(this.account.username);
      await refreshToken();
      location.reload();
    },
    async sendOTP() {
      localStorage.setItem("isSignInAzure", false);
      const phone = this.phone.replace(/[\])[(\s]/g, "");
      try {
        const res = await this.$store.dispatch("notiflow/setSendOTP", phone);
        if (!res.Success) {
          this.$toast.error(res.Message, {
            position: "top",
          });
        } else {
          const startDate = new Date();
          const endDate = new Date(this.getSendOTP.Data.ExpireTime);
          this.countDown = parseInt(
            (endDate.getTime() - startDate.getTime()) / 1000
          );
          await this.countDownTimer();
        }
        this.isSuccessOTP = res.Success;
      } catch (error) {
        if (error.status == 404) {
          this.$toast.error(
            "Telefon numarasına kayıtlı kullanıcı bulunamadı. Lütfen yöneticinizle görüşünüz.",
            {
              position: "top",
            }
          );
        }
        this.resetPage();
      }
    },
    cryptoUserName(name) {
      const CRYPTO_PASSWORD = "lTunes";
      const userName = CryptoJS.AES.encrypt(name, CRYPTO_PASSWORD).toString();
      localStorage.setItem("username", userName);
      localStorage.setItem("isLoggin", true);
    },
    async loginWithOTP() {
      this.cryptoUserName(this.getSendOTP.Data.UserName);
      const body = {
        OTPCode: this.passwordOTP,
        UserName: this.getSendOTP.Data.UserName,
        CustomerId: this.getSendOTP.Data.CustomerId,
        ExpireTime: this.getSendOTP.Data.ExpireTime,
      };
      const res = await this.$store.dispatch("notiflow/setLoginWithOTP", body);
      if (res.Success) {
        this.loginAccount([{ username: res.Data.UserName }]);
      } else {
        this.$toast.error(res.Message, {
          position: "top",
        });
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.timeSeconds = this.countDown;
          this.countDownTimer();
        }, 1000);
      } else {
        this.$toast.error("Üzgünüz süre doldu!", {
          position: "top",
        });
        this.timeSeconds = 0;
        this.isSuccessOTP = false;
      }
    },
    async onLoginTurkuaz(event) {
      const res = await this.$store.dispatch("notiflow/turkuazLogin", event);
      if (res.Success) {
        if (res.Data.IsRequireOtp) {
          //TODO: OTP doğrulama sayfasına yönlendirilecek
          // this.loginAccount([{ username: res.Data.UserName }]);
          this.turkuazLoginData = res;
          this.isSuccessOTP = true;
          const startDate = new Date();
          const endDate = new Date(this.turkuazLoginData.Data.ExpireTime);
          this.countDown = parseInt(
            (endDate.getTime() - startDate.getTime()) / 1000
          );
          await this.countDownTimer();
        } else {
          this.loginAccount([{ username: res.Data.UserName }]);
        }
      } else {
        this.$toast.error(res.Message, {
          position: "top",
        });
        this.resetPage();
      }
    },
    async onLoginBack() {
      this.isTurkuazLogin = false;
    },
    async turkuazLoginWithOTP() {
      const body = {
        OTPCode: this.passwordOTP,
        UserName: this.turkuazLoginData.Data.UserName,
        CustomerId: this.turkuazLoginData.Data.CustomerId,
        ExpireTime: this.turkuazLoginData.Data.ExpireTime,
      };
      const res = await this.$store.dispatch("notiflow/setLoginWithOTP", body);
      if (res.Success) {
        this.loginAccount([{ username: res.Data.UserName }]);
      } else {
        this.$toast.error(res.Message, {
          position: "top",
        });
        this.resetPage();
      }
    },
    resetPage() {
      this.isTurkuazLogin = false;
      this.isSmsLogin = false;
    },
  },
};
</script>

<style lang="scss">
@import "../assets/styles/scss/login.scss";
</style>
